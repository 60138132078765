import React from "react";

class Page extends React.Component {
  render() {
    return (
      <div>
        <div className="conteudo_geral">
          <noscript
            aria-hidden="true"
            dangerouslySetInnerHTML={{
              __html:
                '<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-TQDRJ93" height="0" width="0" style="display:none;visibility:hidden" title="Google Tag Manager">Google Tag Manager</iframe>'
            }}
          />
          <script
            dangerouslySetInnerHTML={{
              __html:
                "\n// **** Scroll na pag 'como-funciona' : ****\njQuery(window).on('load', function() {\nvar numberSlides = 3;\t\t//**** Alterar este nr sempre que for necessario incluir novas div com conteudo nesta pagina especifica; corresponde ao nr total de divs\nvar distance = 500;\nvar timing = 750;\njQuery('#div_pageNr').html('1');\njQuery('#div_setacima').hide();\njQuery('#div_setacima').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) - 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >= numberSlides ){\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\njQuery('#div_setabaixo').click(function() {\nvar next = parseFloat(jQuery('#div_pageNr').html()) + 1;\nvar myDist = '-'+((next * distance) - distance)+'px';\njQuery('.region-content').animate({\"margin-top\": myDist}, timing);\njQuery('#div_pageNr').html(next);\nif(next >=2){\t\t\t//**** Alterar este nr sempre que for necessario ajustar o nr de divs com conteudo a mostrar nesta pagina especifica\njQuery('#div_setabaixo').hide();\n} else {\njQuery('#div_setabaixo').fadeIn();\n}\nif(next <=1){\njQuery('#div_setacima').hide();\n} else {\njQuery('#div_setacima').fadeIn();\n}\n});\n});\n"
            }}
          />
          <div id="head_geral" className="container">
            <div className="container">
              <header id="head" role="banner">
                <div className="five columns alpha">
                  <div className="bisnaga" />
                </div>
                <div className="menu_linha_cima" />
                <div className="menu_linha_abaixo" />
                <div
                  className="eleven columns omega"
                  style={{
                    float: "right"
                  }}
                >
                  <div className="cabecalho_esq">
                    <div className="div_logo">
                      <a href="/" title="Início">
                        <img
                          className="img_logo"
                          src="/sites/default/files/color/responsive/logo.png"
                          alt="Início"
                        />
                      </a>{" "}
                    </div>
                  </div>
                  <nav id="navigation" role="navigation">
                    <div id="main-menu">
                      <ul className="menu">
                        <li className="first leaf">
                          <a href="/o-que-e-biafine">O que é biafine<sup>®</sup></a>
                        </li>
                        <li className="leaf">
                          <a href="/quando-e-como-aplicar">
                            Quando e como <br /> aplicar
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/como-funciona">Como funciona</a>
                        </li>
                        <li className="leaf">
                          <a href="/balsamo-multi-reparador-apaziguante"><span className="blue-text">Cica</span> biafine<sup>®</sup> <br /><span className="align-leaf">Cosmético</span></a>
                        </li>
                        <li className="last leaf">
                          <a href="/contactos">Contactos</a>
                        </li>
                      </ul>{" "}
                    </div>
                  </nav>
                </div>
              </header>
            </div>
          </div>
          <div className="container" id="content-contain">
            <div className="menu_shadow" />
            <div id="content" className="sixteen columns">
              <div id="breadcrumbs">
                <h2 className="element-invisible">Está aqui</h2>
                <nav className="breadcrumb">
                  <a href="/">Início</a> » Política de privacidade
                </nav>
              </div>
              <section id="post-content" role="main">
                <h1 className="page-title">Política de privacidade</h1>
                <div
                  style={{
                    height: "450px",
                    overflow: "scroll",
                    "overflow-x": "hidden"
                  }}
                >
                  <div className="region region-content">
                    <div id="block-system-main" className="block block-system">
                      <div className="content">
                        <span
                          property="dc:title"
                          content="Política de privacidade"
                          className="rdf-meta element-hidden"
                        />
                        <span
                          property="sioc:num_replies"
                          content="0"
                          datatype="xsd:integer"
                          className="rdf-meta element-hidden"
                        />
                        <div className="content node-page">
                          <div className="field field-name-body field-type-text-with-summary field-label-hidden">
                            <div className="field-items">
                              <div
                                className="field-item even"
                                property="content:encoded"
                              >
                                <p>
                                  <strong>
                                    A JNTL Consumer Health (Portugal) Limitada{" "}
                                  </strong>{" "}
                                  preocupa-se com a sua privacidade e quer que você esteja familiarizado com a forma como recolhemos, utilizamos e divulgamos informações.  Esta Política de Privacidade descreve as nossas práticas no âmbito das informações que nós recolhemos ou os nossos prestadores de serviços recolhem através do website ou da aplicação (doravante designados por <strong>"Serviço"</strong>)  operado e controlado por nós, a partir do qual está a aceder a esta Política de Privacidade.  Ao disponibilizar-nos informações pessoais ou ao utilizar o Serviço, você confirma que leu e compreendeu esta Política de Privacidade.
                                </p>
                                <p>
                                  <strong>UTILIZAÇÃO POR MENORES</strong>
                                </p>
                                <p>
                                  O Serviço não é direcionado para indivíduos com idade inferior a <strong>14</strong> anos e solicitamos que estes indivíduos não forneçam informações pessoais através do Serviço. Se o seu filho tiver submetido Informações pessoais e você gostaria de solicitar que tais Informações pessoais fossem removidas, contacte-nos conforme explicado abaixo em Contactar-nos.
                                </p>
                                <p>
                                  <strong>RECOLHA DE INFORMAÇÕES</strong>
                                </p>
                                <p>
                                Podemos pedir-lhe que submeta informações pessoais para poder beneficiar de determinadas funcionalidades (tais como subscrições de newsletter, sugestões/indicadores ou processamento de pedidos) ou para participar numa atividade específica (tais como sorteios ou outras promoções).  Será informado de quais as informações necessárias e que informações são opcionais.
                                </p>
                                <p>
                                  Podemos combinar as informações que submeter com outras informações que recolhermos sobre si, quer online quer offline, incluindo, por exemplo, o seu histórico de compras.  Podemos igualmente combiná-las com informações que recebemos sobre si de empresas Kenvue.
                                </p>
                                <p>
                                Se nos submeter informações pessoais relacionadas com outro indivíduo, você declara que tem autoridade para o fazer e que nos autoriza a utilizar as informações em conformidade com esta Política de Privacidade.
                                </p>
                                <p>
                                  <strong>INFORMAÇÕES SENSÍVEIS</strong>
                                </p>
                                <p>
                                Exceto se especificamente o solicitarmos ou o convidarmos a tal, pedimos-lhe que não nos envie nem divulgue informações pessoais sensíveis (por ex., números da Segurança Social, informações relacionadas com a origem racial ou étnica, opiniões políticas, religião ou crenças filosóficas, condições clínicas e de saúde, vida sexual ou orientação sexual, registo criminal ou filiação sindical, dados biométricos ou genéticos) no ou através do Serviço ou de qualquer outra forma.
                                </p>
                                <p>
                                  <strong>
                                    RECOLHA AUTOMÁTICA DE INFORMAÇÕES E UTILIZAÇÃO {" "}
                                  </strong>
                                </p>
                                <p>
                                  Nós, e os nossos prestadores de serviços, podemos recolher determinadas informações automaticamente enquanto navega pelo Serviço.  Leia a{" "}
                                  <a href="/cookiepolicy.pdf" target="_blank">
                                    Política de cookies
                                  </a>{" "}
                                  para obter informações detalhadas sobre os cookies e outras tecnologias de rastreio utilizadas no Serviço. Salvo quando permitido por lei, colocamos cookies apenas após termos recebido o seu consentimento através do banner de cookies ou centro de gestão de preferências. A Política de Cookies inclui informação sobre como desativar estas tecnologias.
                                  </p>
                                <p>
                                Nós e os nossos prestadores de serviços também podemos recolher automaticamente e utilizar informações através das seguintes formas:
                                </p>
                                <p>
                                  <strong>Através do seu navegador:</strong>{" "}
                                  Algumas informações são recolhidas pela maioria dos navegadores, tais como o endereço de controlo de acesso a suportes de dados (MAC), o tipo de computador (Windows ou Mac), resolução de ecrã, nome e versão do sistema operativo e tipo e versão do navegador Internet.  Podemos recolher informações similares, tais como o tipo e identificador do dispositivo, se aceder ao Serviço através de um dispositivo móvel.  Utilizamos estas informações para assegurar que o Serviço funciona corretamente.
                                  </p>
                                <p>
                                  <strong>Endereço de IP:</strong> O seu endereço de IP é um número que é automaticamente atribuído ao seu computador pelo Fornecedor de serviços de Internet.  Um endereço de IP é identificado e registado automaticamente nos ficheiros de registo do nosso servidor sempre que um utilizador consulta o Serviço, assim como a duração da consulta e as páginas consultadas.  A recolha de endereços de IP é uma prática normalizada e é feita automaticamente por muitos serviços online.  Utilizamos os endereços de IP para fins como o cálculo dos níveis de utilização do Serviço, diagnóstico de problemas no servidor e administração do Serviço.  É igualmente possível aferir a sua localização aproximada a partir do endereço de IP.
                                  </p>
                                <p>
                                  <strong>Informações do dispositivo:</strong>{" "}
                                  Podemos recolher informações sobre o seu dispositivo móvel, tais como um identificador de dispositivo único, para compreender como utiliza o Serviço.
                                  </p>
                                <p>
                                  <strong>
                                  COMO UTILIZAMOS E DIVULGAMOS INFORMAÇÕES
                                  </strong>
                                </p>
                                <p>
                                Utilizamos e divulgamos as informações que nos disponibiliza conforme descrito na secção de recolha.  Consulte a secção intitulada "Escolhas e Acesso" em seguida para saber como pode anular determinadas utilizações e divulgações.
                                </p>
                                <p>
                                Quando exigido por lei, obteremos o seu consentimento para utilizar os seus dados pessoais na secção de recolha de informações. Podemos igualmente utilizar informações suas ou sobre si conforme necessário para celebrar um contrato, cumprir uma obrigação legal (por exemplo, devido às nossas obrigações de farmacovigilância) ou para a prossecução dos nossos interesses comerciais legítimos.  Podemos também contar com outras bases legais, especificamente para:
                                </p>
                                <p>&nbsp;</p>
                                <ul>
                                  <li>
                                    <strong>
                                    Fornecer a funcionalidade do Serviço e satisfazer os seus pedidos.
                                    </strong>
                                    <ul>
                                      <li>
                                        fornecer a funcionalidade do Serviço e fornecer-lhe serviços ao cliente relacionados;
                                      </li>
                                      <li>
                                        responder às suas questões e satisfazer os seus pedidos, como por exemplo, enviar-lhe os documentos que solicitar ou alertas de e-mail;
                                      </li>
                                      <li>
                                        enviar-lhe informações importantes relativamente à nossa relação consigo ou relativamente ao Serviço, alterações aos nossos termos, condições e políticas e/ou outras informações administrativas.
                                      </li>
                                    </ul>
                                    Realizaremos estas atividades para gerir a nossa relação contratual consigo e/ou cumprir uma obrigação legal.
                                  </li>
                                </ul>
                                <p>&nbsp;</p>
                                <ul>
                                  <li>
                                    <strong>
                                      Alcançar os nossos objetivos comerciais.
                                    </strong>
                                    <ul>
                                      <li>
                                        análise de dados, por exemplo, para melhorar a eficiência do Serviço;
                                      </li>
                                      <li>
                                        auditorias, para verificar se os nossos processos internos funcionam como pretendido e estão em conformidade com os requisitos legais, regulamentares ou contratuais;
                                      </li>
                                      <li>
                                        fins de monitorização de fraude e segurança, por exemplo, para detetar e prevenir ataques cibernéticos ou tentativas de roubo de identidade;
                                      </li>
                                      <li>
                                        o desenvolvimento de novos produtos e serviços;
                                      </li>
                                      <li>
                                        potenciar, melhorar ou modificar o nosso site ou produtos e serviços; 
                                      </li>
                                      <li>
                                        identificar as tendências de uso do serviço, por exemplo, para entender que aspetos do nosso Serviço são de maior interesse para os utilizadores; e
                                      </li>
                                      <li>
                                        determinar a eficácia das nossas campanhas promocionais, para que possamos adaptar as nossas campanhas às necessidades e interesses dos nossos utilizadores.
                                      </li>
                                    </ul>
                                    Realizaremos estas atividades para gerir a nossa relação contratual consigo, para cumprir uma obrigação legal e/ou porque temos um interesse legítimo.
                                  </li>
                                </ul>
                                <p>&nbsp;</p>
                                <ul>
                                  <li>
                                    <strong>
                                      Análise de Informações Pessoais para fornecer serviços personalizados.
                                    </strong>
                                    <ul>
                                      <li>
                                        compreendê-lo melhor para que possamos personalizar as nossas interações consigo e fornecer informações e/ou ofertas adaptadas aos seus interesses;
                                      </li>
                                      <li>
                                        entender melhor as suas preferências para que possamos fornecer conteúdo através do Serviço que acreditamos ser relevante e interessante para si.
                                      </li>
                                    </ul>
                                    Forneceremos serviços personalizados mediante o seu consentimento ou porque temos um interesse legítimo.
                                  </li>
                                </ul>
                                <p>
                                Também divulgamos informações recolhidas através do Serviço:
                                </p>
                                <ul>
                                  <li>
                                    às nossas afiliadas para os fins descritos nesta Política de Privacidade.  Poderá encontrar uma lista das nossas afiliadas em{" "}
                                    <a
                                      href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx"
                                      target="_blank"
                                    >
                                      https://investors.kenvue.com/financials-reports/sec-filings/default.aspx
                                    </a>{" "}
                                    - aceder aos documentos apresentados pela Kenvue perante a Securities Exchange Commission.{" "}
                                    <strong>JNTL Consumer Health (Portugal) Limitada </strong> é a entidade responsável pela gestão das Informações pessoais utilizadas em conjunto;
                                  </li>
                                  <li>
                                    aos nossos parceiros externos com os quais oferecemos uma promoção de marca conjunta ou de comercialização conjunta;
                                  </li>
                                  <li>
                                    aos nossos prestadores de serviços externos que prestam serviços, tais como alojamento e moderação do website, alojamento da aplicação móvel, análise de dados, processamento de pagamentos, atendimento de pedidos, fornecimento de infraestruturas, serviços de TI, atendimento ao cliente, serviços de entrega de e-mail e mailing direto, auditorias e outros serviços, a fim de lhes permitir prestar os serviços; e
                                  </li>
                                  <li>
                                    conforme permitido por lei, a terceiros na eventualidade de uma reorganização, fusão, venda, joint venture, cedência, transferência ou outra disposição da totalidade ou de uma parte da nossa empresa, ativos ou ações (incluindo no âmbito de qualquer processo de insolvência ou procedimentos similares).
                                  </li>
                                </ul>
                                <p>
                                Adicionalmente, podemos utilizar e divulgar as suas informações conforme considerarmos necessário ou apropriado: (a) a fim de cumprir qualquer processo jurídico ou lei aplicável, que poderá incluir leis fora do seu país de residência; (b) conforme permitido pela lei aplicável para responder a pedidos de autoridades públicas e estatais, que podem incluir autoridades fora do seu país de residência; (c) para implementar os nossos termos e condições; e (d) para proteger os nossos direitos, privacidade, segurança ou propriedade e/ou os direitos, segurança ou propriedade das nossas afiliadas, suas ou de outros.  Podemos também utilizar e divulgar as suas informações de outras formas, depois de obtermos o seu consentimento para tal.
                                </p>
                                <p>
                                Podemos utilizar e divulgar as informações que recolhemos automaticamente conforme descrito na secção "Recolha automática de informações e utilização".
                                </p>
                                <p>
                                Adicionalmente, quando permitido pela lei aplicável, podemos utilizar e divulgar informações que não estejam sob a forma de informações pessoais identificáveis, para qualquer fim. Se combinarmos informações que não estão sob a forma de informações pessoais identificáveis com informações identificáveis (como por exemplo, combinar o seu nome com a localização geográfica), trataremos as informações combinadas como informações pessoais.
                                </p>
                                <p>
                                  <strong>ESCOLHAS E ACESSO</strong>
                                </p>
                                <p>
                                  <strong>
                                  As suas escolhas relativamente à utilização e divulgação das suas informações pessoais
                                  </strong>
                                </p>
                                <p>
                                Nós damos-lhe escolhas relativamente à utilização e divulgação das suas informações pessoais para fins de marketing.  Poderá optar por não:
                                </p>
                                <ul>
                                  <li>
                                    <strong>
                                      Receber comunicações de marketing da nossa parte:
                                    </strong>{" "}
                                    Se já não pretender receber comunicações de marketing da nossa parte, poderá optar por não as receber contactando-nos através de{" "}
                                    <a
                                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p3"
                                      target="_blank"
                                    >
                                      formulário de contato
                                    </a>
                                    . No pedido, inclua o seu nome, identifique as formas de comunicações de marketing que não deseja receber mais e inclua os endereços para os quais as comunicações são enviadas.  Por exemplo, se não pretender receber mais e-mails de marketing ou mailings diretos da nossa parte, informe-nos e indique o seu nome e e-mail ou endereço postal. Adicionalmente, pode optar por não receber e-mails de marketing da nossa parte seguindo as instruções de cancelamento de subscrições incluídas em qualquer mensagem deste género.
                                  </li>
                                  <li>
                                    <strong>
                                      Receber lembretes da nossa parte:{" "}
                                    </strong>{" "}
                                    Se já não pretender receber lembretes médicos da nossa parte, poderá optar por não os receber contactando-nos através de{" "}
                                    <a
                                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p3"
                                      target="_blank"
                                    >
                                      formulário de contato
                                    </a>
                                    . Na resposta, indique o seu nome e o endereço de e-mail ou número de telefone no qual recebe os nossos lembretes.
                                  </li>
                                  <li>
                                    <strong>
                                      A nossa partilha das suas informações pessoais com afiliadas e parceiros externos:{" "}
                                    </strong>{" "}
                                    Se tiver optado anteriormente por receber comunicações de marketing das nossas afiliadas ou parceiros externos, poderá cancelar a nossa partilha das suas informações pessoais com estas partes para os seus fins de marketing direto contactando-nos através de{" "}
                                    <a
                                      href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p3"
                                      target="_blank"
                                    >
                                      formulário de contato
                                    </a>
                                    . Na comunicação, indique que já não pretende que partilhemos as suas informações pessoais com os nossos afiliados e/ou parceiros externos para os seus fins de marketing e inclua o seu nome e endereço de e-mail.
                                  </li>
                                </ul>
                                <p>
                                Procuraremos satisfazer o seu pedido assim que possível.  Tenha em atenção que, se optar por cancelar estas subscrições, conforme descrito acima, não será possível eliminar as suas informações pessoais das bases de dados das nossas afiliadas com quem já partilhámos informações (ou seja, a partir da data em que implementamos o seu pedido de cancelamento).  Todavia, empregaremos esforços razoáveis para informar as nossas afiliadas do seu pedido. Tenha também em atenção que, se optar por deixar de receber mensagens relacionadas com marketing da nossa parte, poderemos continuar a enviar-lhe mensagens transacionais e administrativas importantes, sendo que não é possível cancelar estas mensagens.
                                </p>
                                <p>
                                  <strong>
                                  Como aceder, alterar ou eliminar as suas informações pessoais
                                  </strong>
                                </p>
                                <p>
                                Se pretender rever, corrigir, atualizar, limitar ou eliminar as suas informações pessoais, ou se pretender solicitar uma cópia eletrónica das suas informações pessoais para as transmitir para outra empresa) dos seus dados pessoais (na medida em que estes direitos lhe sejam conferidos pela lei aplicável), contacte-nos através de{" "}
                                  <a
                                    href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p3"
                                    target="_blank"
                                  >
                                    formulário de contato
                                  </a>
                                  . Responderemos ao seu pedido assim que possível, no prazo máximo de um mês após a receção do pedido. Se determinadas circunstâncias causarem algum atraso na nossa resposta, será prontamente notificado e indicar-lhe-emos uma data na qual irá receber a nossa resposta.
                                  </p>
                                <p>
                                  <strong>TRANSFERÊNCIA INTERNACIONAL</strong>
                                </p>
                                <p>
                                As suas informações pessoais podem ser armazenadas e processadas em qualquer país onde temos instalações ou prestadores de serviços e, ao utilizar o nosso Serviço ou ao dar-nos o seu consentimento (quando exigido por lei), as suas informações podem ser transferidas para países fora do seu país de residência, incluindo para os Estados Unidos, que podem ter regras de proteção de dados diferentes do seu país. Não obstante, implementamos medidas contratuais apropriadas e outras medidas para proteger informações pessoais aquando da transferência para as nossas afiliadas ou terceiros noutros países.
                                </p>
                                <p>
                                Alguns países fora do Espaço Económico Europeu (EEE) são reconhecidos pela Comissão Europeia como tendo um nível adequado de proteção de dados de acordo com as normas do EEE (poderá encontrar a lista completa destes países{" "}
                                  <a
                                    href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_pt"
                                    target="_blank"
                                  >
                                    aqui
                                  </a>
                                  . Para transferências do EEE para países não considerados adequados pela Comissão Europeia, assegurámos a implementação de medidas adequadas para proteger as suas Informações pessoais, incluindo assegurar que o destinatário está vinculado por <strong>Cláusulas
                                    Contratuais-Tipo da UE.</strong>
                                  Pode obter uma cópia destas medidas contactando o nosso encarregado da proteção de dados em conformidade com a secção <i>"Contactar-nos"</i> abaixo.
                                </p>
                                <p>
                                  <strong>SEGURANÇA</strong>
                                </p>
                                <p>
                                  Procuramos utilizar medidas organizacionais, técnicas e administrativas desenvolvidas para proteger as informações pessoais sob o nosso controlo.  Infelizmente, não é possível garantir que uma transmissão de dados pela Internet ou sistema de armazenamento de dados seja 100% segura.  Se tiver razões para acreditar que a sua interação connosco já não é segura (por exemplo, se acreditar que a segurança de uma conta que tem connosco foi comprometida), notifique-nos de imediato em conformidade com a secção <i>"Contactar-nos"</i> abaixo.
                                </p>
                                <p>
                                  <strong>PERÍODO DE RETENÇÃO</strong>
                                </p>
                                <p>
                                Mantemos as suas informações pessoais durante o período de tempo necessário ou permitido em função das finalidades para as quais foram obtidas.  Os critérios utilizados para determinar os períodos de retenção incluem:  (i) o período de tempo durante o qual temos uma relação consigo e lhe prestamos o Serviço; (ii) se existe uma obrigação legal à qual estamos sujeitos; e (iii) se a retenção é aconselhável em função da nossa posição jurídica (como por exemplo, relativamente a leis de prescrição, litigação ou investigações regulamentares).
                                </p>
                                <p>
                                  <strong>SITES E SERVIÇOS DE TERCEIROS</strong>
                                </p>
                                <p>
                                Este Serviço poderá conter hiperligações para sites de terceiros. Esta Política de Privacidade não aborda e nós não nos responsabilizamos pela privacidade, informações ou práticas de terceiros, incluindo quaisquer terceiros que operem um site ou serviço online (incluindo, sem limitação, qualquer aplicação) disponibilizados através deste Serviço ou para o qual este Serviço inclui uma ligação.  A disponibilização ou inclusão de uma ligação para qualquer site ou propriedade no Serviço não implica a nossa recomendação ou a recomendação das nossas afiliadas.
                                </p>
                                <p>
                                  <strong>CONTACTAR-NOS</strong>
                                </p>
                                <p>
                                  <strong>
                                    JNTL Consumer Health (Portugal) Limitada,
                                  </strong>{" "}
                                  situada em <strong>Lagoas Park, Edifício 9, 2740-262 PORTO SALVO</strong>, é a empresa responsável pela recolha, utilização e divulgação de informações pessoais ao abrigo desta Política de Privacidade.
                                </p>
                                <p>
                                Se tiver dúvidas acerca desta Política de Privacidade, contacte-nos através de{" "}
                                  <a
                                    href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p3"
                                    target="_blank"
                                  >
                                    formulário de contato
                                  </a>
                                  , ou escreva-nos para o seguinte endereço:
                                </p>
                                <p>
                                  <strong>
                                    JNTL Consumer Health (Portugal) Limitada
                                    <br />
                                    Lagoas Park, Edifício 9.
                                    <br />
                                    2740-262 PORTO SALVO
                                  </strong>
                                </p>
                                <p>
                                  Pode igualmente contactar o encarregado da proteção de dados do seu país ou região, se aplicável, através do e-mail{" "}
                                  <a href="mailto:emeaprivacy@kenvue.com">
                                    <strong>emeaprivacy@kenvue.com</strong>
                                  </a>
                                  .
                                </p>
                                <p>
                                  <strong>
                                  APRESENTAR UMA RECLAMAÇÃO JUNTO DE UMA ENTIDADE REGULADORA
                                  </strong>
                                </p>
                                <p>
                                Poderá apresentar uma reclamação junto de uma autoridade de controlo competente no seu país ou região. Clique{" "}
                                  <a
                                    href="https://edpb.europa.eu/about-edpb/board/members_pt"
                                    target="_blank"
                                  >
                                    aqui
                                  </a>{" "}
                                  para obter as informações de contacto dessas autoridades.
                                </p>
                                <p>
                                  <strong>
                                  ATUALIZAÇÕES A ESTA POLÍTICA DE PRIVACIDADE
                                  </strong>
                                </p>
                                <p>
                                  Esta Política de Privacidade pode ser alterada. Quaisquer alterações a esta Política de Privacidade entram em vigor quando publicarmos a Política de Privacidade revista no Serviço. A sua utilização do Serviço após estas alterações significa que aceita a Política de Privacidade revista. Recomendamos que reveja regularmente a Política de Privacidade quando visitar o Serviço. Esta política foi atualizada pela última vez em 02-06-2023.
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <footer></footer>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
            <div className="clear" />
          </div>
          <div id="copyright_geral" className="container">
            <div id="copyright" className="container">
              <div className="background_img_interior" />
              <div className="rodape_esq">
                <div className="region region-footer">
                  <div id="block-block-1" className="block block-block">
                    <div className="content">
                      <p>
                        www.biafine.pt © JNTL Consumer Health (Portugal) Limitada, 2024.                        
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodapeDir rodape_dir">
                <div className="region region-footer-menu">
                  <div
                    id="block-menu-menu-menu-rodape"
                    className="block block-menu"
                  >
                    <div className="content">
                      <ul className="menu">
                        <li className="first leaf active-trail">
                          <a
                            href="/politica-de-privacidade"
                            title
                            className="active-trail active"
                          >
                            POLÍTICA DE PRIVACIDADE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/cookie-policy" title>
                            POLÍTICA DE COOKIES
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/aviso-legal" title>
                            AVISO LEGAL
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/mapa-do-site" title>
                            MAPA DO SITE
                          </a>
                        </li>
                        <li className="leaf">
                          <a href="/contactos" title>
                            CONTACTOS
                          </a>
                        </li>

                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="rodape_centro">
                <div className="region region-footer-center">
                  <div id="block-block-4" className="block block-block">
                    <div className="content">
                      <div>
                        Este site é publicado pela JNTL Consumer Health (Portugal) Limitada única responsável pelo seu
                        conteúdo.&nbsp;Destina-se a utilizadores em Portugal.
                      </div>
                      <div>
                        V08 <b>Biafine<sup>®</sup></b>, trolamina. Medicamento indicado em queimaduras de 1º grau, eritema solar e feridas
                        superficiais não infetadas. Afastar dos olhos. Contém excipientes que podem originar reações
                        cutâneas/alérgicas. Contraindicado em caso de hipersensibilidade aos componentes. Leia
                        cuidadosamente o folheto informativo. Em caso de dúvida ou de persistência dos sintomas consulte o
                        seu médico ou farmacêutico. JNTL Consumer Health (Portugal), Lda. PT-BIA-2400001. 04/2024
                        <br />
                        &nbsp;
                      </div>
                      <div>&nbsp;</div>
                      <div className="last leaf">
                        <a id="ot-sdk-btn" className="ot-sdk-show-settings">
                          Configuração de cookies
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="clear" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Page;
